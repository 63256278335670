import {POST, GET} from './index'

// api/ArriveStore/init 门店初始化
function init (params) {
  return GET('/api/ArriveStore/init',params)
}

// api/ArriveStore/add 新增店铺预约信息
function add (params) {
  return POST('/api/ArriveStore/add',params)
}

export default {
  init,
  add
}
